import { UcoastEl } from '@/scripts/core/UcoastEl'
import {addToKlaviyoListConfig, getBackendRoute, qsRequired} from "@/scripts/core/global";

export class KlaviyoForm extends UcoastEl {
  static htmlSelector = 'klaviyo-form'
  form: HTMLFormElement
  submitButton: HTMLButtonElement
  emailInput: HTMLInputElement
  route: string

  constructor() {
    super()
    this.form = qsRequired('form', this)
    this.submitButton = qsRequired('[type="submit"]', this)
    this.emailInput = qsRequired('[type="email"]', this)
    this.route = `${getBackendRoute()}/api/klaviyo-list`
    this.form.addEventListener('submit', this.onSubmitHandler.bind(this))
  }


  onSubmitHandler(event: Event) {
    event.preventDefault()
    if (this.submitButton.getAttribute('aria-disabled') === 'true') return
    if (this.emailInput.value.length < 1) return

    this.submitButton.setAttribute('aria-disabled', 'true')
    this.submitButton.classList.add('loading')

    const formData = new FormData(this.form)

    const config = addToKlaviyoListConfig(formData)

    fetch(this.route, config)
      .then((response) => response.json())
      .then((response) => {
        this.emailInput.value = ''
        if (response.success) {
          this.classList.add('success')
        } else {
          this.emailInput.placeholder = 'This email address is invalid.'
        }
      })
      .catch((e) => {
        console.error(e)
      })
      .finally(() => {
        this.submitButton.classList.remove('loading')
        this.submitButton.removeAttribute('aria-disabled')
      })
  }
}
