import {
  closestRequired,
  currentTargetRequired,
  getAttributeOrThrow,
  getAttributeOrUndefined,
  qsaOptional,
  qsRequired,
} from '@/scripts/core/global'
import { BasicAccordion } from '@/scripts/theme/basic-accordion'
import {QuantityInput} from "@/scripts/theme/quantity-input";

export class UcoastSelect extends BasicAccordion {
  static override htmlSelector = 'ucoast-select'
  inputElement: HTMLInputElement
  options?: NodeListOf<HTMLButtonElement>
  listenerSelector?: string
  selectPanel: HTMLElement
  labelEl: HTMLElement
  otherSelects?: NodeListOf<UcoastSelect>
  optionName: string
  selectAction?: string
  formId?: string
  relatedProvinceContainer?: HTMLElement

  constructor() {
    super()
    this.inputElement = qsRequired('input[type=hidden], select[data-uc-select-shopify-select]', this)
    this.optionName = getAttributeOrThrow('data-uc-option-name', this)
    this.labelEl = qsRequired('[data-uc-select-label]', this)
    this.options = qsaOptional('button[data-uc-select-option]', this)
    this.listenerSelector = getAttributeOrUndefined('data-uc-select-listener-el', this)
    this.selectPanel = qsRequired('[data-uc-select-panel]', this)
    this.options?.forEach((option) => {
      option.addEventListener('click', this.setValue.bind(this))
    })
    this.otherSelects = qsaOptional(
      `ucoast-select:not([data-uc-option-name="${this.optionName}"])`
    )
    this.selectAction = getAttributeOrUndefined('data-uc-select-action', this)
    this.formId = getAttributeOrUndefined('data-uc-form-id', this)

    if (this.selectAction === 'country' && this.formId) {
      this.inputElement = qsRequired('select[data-uc-select-shopify-select]')
    }
  }
  override open(opener: HTMLElement, disableInitialFocus: boolean = false) {
    super.open(opener, disableInitialFocus)
    this.otherSelects?.forEach((select) => {
      select.close()
    })
  }

  setValue(event: Event) {
    const currentTarget = currentTargetRequired(event)
    const value = getAttributeOrThrow('data-uc-select-option', currentTarget)
    if (this.selectAction === 'navigate') {
      window.location.href = value
      return
    }
    this.inputElement.value = value
    this.labelEl.innerHTML = currentTarget.innerHTML
    if (this.selectAction === 'quantity') {
      const quantityInput = closestRequired<QuantityInput>(this, 'quantity-input')
      quantityInput.signalChangeEvent()
    } else if (this.selectAction === 'country') {
      qsRequired<HTMLOptionElement>(`option[value="${value}"]`, this.inputElement).selected = true
      const event = new Event('change', {
        'bubbles': false,
        'cancelable': true
      });
      this.inputElement.dispatchEvent(event)
    } else if (this.selectAction === 'province') {
      qsRequired<HTMLOptionElement>(`option[value="${value}"]`, this.inputElement).selected = true
    } else {
      this.inputElement.dispatchEvent(
        new CustomEvent('ucoast-select-change', {
          bubbles: true,
          detail: {
            name: this.optionName,
            value,
          },
        })
      )
    }

    this.close(this.opener)
    if (this.selectAction === 'submitParentForm') {
      const parentForm = closestRequired<HTMLFormElement>(this, 'form')
      parentForm.submit()
    }
  }
  resetOptionsListeners() {
    this.options = qsaOptional('button[data-uc-select-option]', this)
    this.options?.forEach((option) => {
      option.addEventListener('click', this.setValue.bind(this))
    })
  }
}
