import { UcoastEl } from '@/scripts/core/UcoastEl';
import {currentTargetRequired, qsOptional, qsRequired, removeTrapFocus, trapFocus} from "@/scripts/core/global";

export class BasicAccordion extends UcoastEl {
  static htmlSelector = 'basic-accordion'
  summary: HTMLElement
  detail: HTMLDetailsElement
  opener?: HTMLElement
  heightControlledElement?: HTMLElement
  hotReload: boolean
  constructor() {
    super()
    this.hotReload = this.hasAttribute('data-uc-hot-reload')
    this.summary = qsRequired('summary', this)
    this.detail = qsRequired('details', this)
    this.heightControlledElement = qsOptional('[data-uc-accordion-height-controlled]', this)

    document.addEventListener("DOMContentLoaded", () => {
      if (this.summary.getAttribute('aria-expanded') === 'true') {
        this.open(this.summary, true)
      }
    });

  }
  override connectedCallback() {
    super.connectedCallback();
    this.reInit()
  }

  reInit() {
    this.summary = qsRequired('summary', this)
    this.detail = qsRequired('details', this)
    this.heightControlledElement = qsOptional('[data-uc-accordion-height-controlled]', this)
    this.summary.addEventListener('click', (event) => {
      event.preventDefault()
      const target = currentTargetRequired(event)
      if (this.getAttribute('data-uc-accordion') !== 'open') {
        this.open(target)
      } else {
        this.close(target)
      }
    })
  }

  open(opener: HTMLElement, disableInitialFocus = false) {
    this.detail.setAttribute('open','')
    window.setTimeout(() => {
      this.setAttribute('data-uc-accordion', 'open')
      this.summary.setAttribute('aria-expanded', 'true')
      if (this.heightControlledElement) {
        this.heightControlledElement.style.maxHeight = `${this.heightControlledElement.scrollHeight}px`
      }
    }, 2)
    if (!disableInitialFocus) {
      trapFocus(this.detail, opener)
    }
  }
  close(opener?: HTMLElement) {

    this.setAttribute('data-uc-accordion', 'close')
    this.summary.setAttribute('aria-expanded', 'false')
    if (this.heightControlledElement) {
      this.heightControlledElement.style.maxHeight = '0px'
    }
    window.setTimeout(() => {
      this.detail.removeAttribute('open')
    }, 302)
    removeTrapFocus(opener)
  }


}
